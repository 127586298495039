import configs from 'configs';
import { updateVector3OrPointByRotationXZ } from 'containers/world/utils';
import { gsap } from 'gsap';
import { memo, useEffect, useRef } from 'react';
import { TRANSITION_METHOD } from '../transition.const';
import { createTripodMarker } from './tripodMarker';
import SceneInteractionTracker from './SceneInteractionTracker';
import CubeGroup from './cubeGroup';

const SCALE_RANGE = {
  BIG: 10,
  NORMAL: 1,
};

const DrawTripodMaker = ({ pano, meshRef, tripodMarkerUrl }) => {
  const markerRef = useRef(null);

  useEffect(() => {
    const currentMesh = meshRef.current;

    if (
      pano &&
      pano?.sceneType &&
      pano?.sceneType !== 'AERIAL' &&
      tripodMarkerUrl
    ) {
      createTripodMarker(tripodMarkerUrl).then((marker) => {
        if (currentMesh) {
          markerRef.current = marker;
          currentMesh.add(marker);
        }
      });
    } else {
      if (currentMesh && markerRef.current) {
        currentMesh.remove(markerRef.current);
        markerRef.current = null;
      }
    }

    return () => {
      if (currentMesh && markerRef.current) {
        currentMesh.remove(markerRef.current);
        markerRef.current = null;
      }
    };
  }, [tripodMarkerUrl, pano, meshRef]);

  return <></>;
};

function CubeEffect({ stepHandlers, tripodMarkerUrl, pano }) {
  const meshRef = useRef(null);

  const sceneViewRef = useRef();

  useEffect(() => {
    meshRef.current.scale.set(
      -SCALE_RANGE.NORMAL,
      SCALE_RANGE.NORMAL,
      SCALE_RANGE.NORMAL
    );
    meshRef.current.updateMatrix();
  }, [meshRef]);

  useEffect(() => {
    const sceneId = stepHandlers.sceneData?.id || '';
    meshRef.current.name = sceneId;
  }, [meshRef, stepHandlers]);

  useEffect(() => {
    const { active, stepPosition, rotations } = stepHandlers;
    // setOpacity(active ? 1 : 0);
    const shouldAnimate =
      window.transitionMethod === TRANSITION_METHOD.GROUND_NAV;
    const [prevRot, currRot] = rotations.slice(-2);

    if (active) {
      meshRef.current.rotation.y = currRot?.rad || 0;
      meshRef.current.updateMatrix();
      // animation to show the scene
      if (shouldAnimate) {
        let fromPosition = updateVector3OrPointByRotationXZ(
          stepPosition,
          prevRot?.rad || 0
        );
        fromPosition = fromPosition.normalize().multiplyScalar(1500);
        const transformData = {
          x: fromPosition.x,
          z: fromPosition.z,
          scale: SCALE_RANGE.BIG,
        };
        meshRef.current.position.copy(fromPosition);
        meshRef.current.scale.set(
          -SCALE_RANGE.BIG,
          SCALE_RANGE.BIG,
          SCALE_RANGE.BIG
        );
        gsap.to(transformData, {
          x: 0,
          z: 0,
          scale: SCALE_RANGE.NORMAL,
          duration: configs.transitionSpeed,
          onUpdate: () => {
            const { x, z, scale } = transformData;
            meshRef.current.position.x = x;
            meshRef.current.position.z = z;
            meshRef.current.scale.set(-scale, scale, scale);
            meshRef.current.updateMatrix();
          },
        });
      }
    } else {
      // animation to hide the scene away
      if (shouldAnimate) {
        const { positionNext } = stepHandlers;
        meshRef.current.position.set(0, 0, 0);
        meshRef.current.updateMatrix();
        let nextPosition = updateVector3OrPointByRotationXZ(
          positionNext,
          prevRot?.rad || 0
        );
        nextPosition = nextPosition.normalize();
        nextPosition = nextPosition.multiplyScalar(1500);
        setTimeout(() => {
          meshRef.current.position.copy(nextPosition);
          meshRef.current.scale.set(
            -SCALE_RANGE.BIG,
            SCALE_RANGE.BIG,
            SCALE_RANGE.BIG
          );
          meshRef.current.updateMatrix();
        }, configs.transitionSpeed * 0.9 * 1000);
      }
    }
  }, [stepHandlers]);

  const mouseUp = () => {
    sceneViewRef.current?.handleMouseUp();
  };

  const mouseDown = () => {
    sceneViewRef.current?.handleMouseDown();
  };

  return (
    <mesh
      ref={meshRef}
      matrixAutoUpdate={false}
      onPointerUp={mouseUp}
      onPointerDown={mouseDown}
    >
      <SceneInteractionTracker
        ref={sceneViewRef}
        meshRef={meshRef}
        pano={pano}
      />
      <DrawTripodMaker
        pano={pano}
        meshRef={meshRef}
        tripodMarkerUrl={tripodMarkerUrl}
      />
      {pano?.panoType === 'Image360' && <CubeGroup />}
    </mesh>
  );
}

export default memo(CubeEffect);

import React from 'react';

import Icon from './square-icons/custom.png';

const SquareCustomIcon = () => {
  return (
    <img
      style={{ height: 30, width: 30 }}
      src={Icon}
      alt="square-custom-icon"
    />
  );
};

export default SquareCustomIcon;

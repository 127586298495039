import React from 'react';

function PopupEmbed({ media }) {
  const iframe = media?.embed.iframe;
  return (
    <div className="Popup-content Popup-content-iframe">
      <div className="Popup-content-wp">
        <div
          className="html-embeded-content"
          dangerouslySetInnerHTML={{
            __html: iframe,
          }}
        ></div>
      </div>
    </div>
  );
}

export default PopupEmbed;

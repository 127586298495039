import { useThree } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { getPositionBySide } from 'utils/positionHelper';
import { SCENE_DATA, SIDE, SIZE_IMAGE_TEXTURE, SIZE_IMG } from './constants';
import FaceGroup from './FaceGroup';
import { useSelector } from 'react-redux';

const { SIZE_1024, SIZE_2048, SIZE_4096, SIZE_OLD } = SIZE_IMAGE_TEXTURE;

const CubeGroup = () => {
  const initialState = {
    current: SIZE_1024,
    mixFactor: 0,
    active: 0,
    preload: [
      { id: SIZE_2048, isActive: false, isPreloaded: false },
      { id: SIZE_4096, isActive: false, isPreloaded: false },
      { id: SIZE_1024, isActive: true, isPreloaded: true },
    ],
  };

  const {
    cubeMapImages = {},
    id: sceneId,
    panoType,
  } = useSelector(({ currentScene }) => currentScene);
  const isOldBox = !Object.keys(cubeMapImages?.splitImageUrls || {}).length;

  const { camera } = useThree();
  const getSizeByCamera = () => {
    const currentCamera = camera.fov;
    return currentCamera > 100
      ? SIZE_1024
      : currentCamera > 30
      ? SIZE_2048
      : SIZE_4096;
  };

  const [state, setState] = useState(() => {
    return {
      ...initialState,
      current: getSizeByCamera(),
      textures: [{ id: sceneId, cubeMapImages, isOldBox }, {}],
      trigger: 0,
      isFirstLoad: true,
    };
  });
  const facesRef = useRef([]);
  const currentSceneRef = useRef(sceneId);

  const {
    current,
    preload,
    mixFactor,
    textures,
    trigger,
    active: finalActive,
    isFirstLoad,
  } = state;
  const { id: finalId } = preload.find((i) => i.isPreloaded && i.isActive);

  const getSizeScene = (value) => {
    setState((prev) => {
      let size = prev.current;
      switch (prev.current) {
        case SIZE_1024:
          if (value < 100) size = SIZE_2048;
          break;
        case SIZE_2048:
          if (value <= 30) size = SIZE_4096;
          else if (value >= 100) size = SIZE_1024;
          break;
        case SIZE_4096:
          if (value >= 60) size = SIZE_2048;
          break;
        default:
          break;
      }
      return { ...prev, current: size };
    });
  };

  const getSceneData = ({ sizeImg, side, textureId }) => {
    if (!textureId) return null;
    const finalSize = isOldBox ? SIZE_OLD : sizeImg;
    const dataSide = {
      ...(SCENE_DATA.find((item) => item.name === side) || {}),
    };
    const { quantity, step } = SIZE_IMG[finalSize];
    const positions = getPositionBySide(finalSize);
    const texture = textures.find((item) => item?.id === textureId);
    const { cubeMapImages: cubeMapImagesCurrent, isOldBox: isOldBoxCurrent } =
      texture || {};

    dataSide['positionPlane'] = Array.from(
      {
        length: quantity,
      },
      (_, index) => {
        const finalImageUrl = isOldBoxCurrent
          ? cubeMapImagesCurrent[sizeImg][
              SCENE_DATA.findIndex((item) => item.name === side)
            ]
          : cubeMapImagesCurrent?.splitImageUrls?.[sizeImg]?.[side]?.[index];
        return {
          textureId,
          position: positions[index] || [0, 0, 0],
          size: step,
          imageUrl: finalImageUrl,
        };
      }
    );

    return dataSide;
  };

  const handleScroll = (e) => {
    if (e.deltaY > 0) {
      if (camera.fov >= 120) return;
      camera.fov += 2;
    } else {
      if (camera.fov <= 10) return;
      camera.fov -= 2;
    }
    camera.updateProjectionMatrix();
    getSizeScene(camera.fov);
  };

  const handlePreload = async ({ sizeImg, textureId }) => {
    await Promise.all(
      facesRef.current.map((item) => item?.preloadImage({ sizeImg, textureId }))
    );
    setState((prev) => {
      return {
        ...prev,
        preload: prev.preload.map((item) => {
          const currentActive = prev.current === sizeImg;

          if (item.id === sizeImg) {
            return {
              ...item,
              isActive: currentActive,
              isPreloaded: true,
            };
          }
          return {
            ...item,
            isActive: !currentActive ? item.isActive : false,
          };
        }),
      };
    });
  };

  const handlePreloadNewScene = async ({ trigger }) => {
    if (trigger === finalActive) return;
    await Promise.all(
      facesRef.current.map((item) =>
        item?.preloadImage({ sizeImg: current, textureId: sceneId })
      )
    );
    setState((prev) => ({ ...prev, active: 1, mixFactor: 1 }));

    setTimeout(() => {
      setState((prev) => ({
        ...prev,
        textures: [{ ...prev.textures[1] }, { ...prev.textures[1] }],
        mixFactor: 0,
        current: getSizeByCamera(),
      }));
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!finalActive && !isFirstLoad) return;
    handlePreload({ sizeImg: current, textureId: sceneId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, finalActive, sceneId]);

  useEffect(() => {
    handlePreloadNewScene({ trigger });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (sceneId === currentSceneRef.current) return;
    if (panoType === 'Pano') return;
    setState((prev) => {
      const index = prev.textures.findIndex(
        (item) => item.id === currentSceneRef.current
      );
      if (index === -1) return prev;
      const newTextures = [
        { ...prev.textures[0] },
        { id: sceneId, cubeMapImages, isOldBox },
      ];
      return {
        ...prev,
        textures: newTextures,
        trigger: sceneId,
        ...initialState,
        isFirstLoad: false,
      };
    });

    return () => {
      currentSceneRef.current = sceneId;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneId]);

  return SIDE.map((side, index) => (
    <FaceGroup
      ref={(ref) => (facesRef.current[index] = ref)}
      key={side}
      side={side}
      size={finalId}
      onGetSceneData={getSceneData}
      textures={textures}
      mixFactor={mixFactor}
      sceneId={sceneId}
      indexActive={finalActive}
    />
  ));
};

export default CubeGroup;

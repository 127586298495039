import React from 'react';

import './canvasPulsePoint.scss';
import HOTSPOT_TYPE from '../../../consts/hotspotType.const';

export default function CanvasPulsePoint({
  hotspot,
  isBaseClientHotspotStyle,
}) {
  const { type } = hotspot;

  if (isBaseClientHotspotStyle)
    return (
      <div
        className={`base-client ${
          type === HOTSPOT_TYPE.SCENE ? 'jumspot-style' : ''
        }`}
      ></div>
    );
  return <div className="circle"></div>;
}

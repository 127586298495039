import React, { useEffect, useRef, useState } from 'react';

import MEDIA_TYPES from '../../../consts/mediaType.const';
import Square3DModelIcon from './square-3dmodel-icon';

import './SquareHotspotIcon.scss';
import { limitLetters } from 'utils/limitLetters';
import SquareVideoIcon from './square-video-icon';
import SquareInfoBoxIcon from './square-infobox-icon';
import SquarePDFIcon from './square-pdf-icon';
import SquareGalleryIcon from './square-gallery-icon';
import SquareMatterportIcon from './square-matterport-icon';
import SquareGoogle360Icon from './square-google360-icon';
import SquareMaploorplanIcon from './square-mapfloorplan-icon';
import SquareTypeformIcon from './square-typeform-icon';
import SquareInfotag from './square-infortag';
import SquareCustomIcon from './square-custom-icon';
import SquareEmbedIcon from './square-embed-icon';
import SquareLinkIcon from './square-link-icon';
import SquareShapesparkIcon from './square-shapespark-icon';
import SquareLumasIcon from './square-lumas-icon';
import SquareSketchfabIcon from './square-sketchfab-icon';

export default function SquareHotspotIcon(props) {
  const { title, type, sceneType } = props.data;
  const containerRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const newHeight = containerRef.current.offsetHeight;
      setHeight(newHeight);
    }
  }, [props.data]);

  useEffect(() => {
    if (height > 0) {
      const param = (height / 296.8421052631579) * 100;
      setTranslateY(param < 50 ? param + 52 : param);
    }
  }, [height]);

  const getIcon = (type) => {
    if (type === MEDIA_TYPES.YOUTUBE || type === MEDIA_TYPES.VIDEO) {
      return SquareVideoIcon;
    } else if (type === MEDIA_TYPES.EMBED) {
      return SquareEmbedIcon;
    } else if (type === MEDIA_TYPES.LINK) {
      return SquareLinkIcon;
    } else if (type === MEDIA_TYPES.IMAGE) {
      return SquareInfoBoxIcon;
    } else if (type === MEDIA_TYPES.PDF) {
      return SquarePDFIcon;
    } else if (type === MEDIA_TYPES.INFO_TAG) {
      return SquareInfotag;
    } else if (type === MEDIA_TYPES.GALLERY) {
      return SquareGalleryIcon;
    } else if (type === MEDIA_TYPES.MATTERPORT) {
      return SquareMatterportIcon;
    } else if (type === MEDIA_TYPES.GOOGLE360) {
      return SquareGoogle360Icon;
    } else if (type === MEDIA_TYPES.MAPFLOORPLAN) {
      return SquareMaploorplanIcon;
    } else if (type === MEDIA_TYPES.TYPEFORM) {
      return SquareTypeformIcon;
    } else if (type === MEDIA_TYPES.SHAPESPARK) {
      return SquareShapesparkIcon;
    } else if (type === MEDIA_TYPES.SKETCH_FAB) {
      return SquareSketchfabIcon;
    } else if (type === MEDIA_TYPES.LUMA) {
      return SquareLumasIcon;
    } else if (type === MEDIA_TYPES.MODEL_3D) {
      return Square3DModelIcon;
    } else {
      return SquareCustomIcon;
    }
  };

  const Icon = getIcon(type);
  if (!Icon && !props.data.sceneType) {
    return null;
  }

  const onClickHotspot = (e) => {
    e.stopPropagation();
    if (props.isHover) {
      props.toggleHover(false);
    }
    props.onClick();
  };

  if (sceneType) {
    const sceneDetail = props.data;
    return (
      <div
        className="square-jumpspot"
        onClick={() => setTimeout(props.onClick(), 0)}
      >
        <div className="square-jumpspot--container">
          <div className="square-jumpspot--previewimg">
            <img src={sceneDetail.previewImgUrl} alt={sceneDetail.title} />
          </div>

          <div className="square-jumpspot--title">
            <p>{sceneDetail.title}</p>
          </div>
        </div>
      </div>
    );
  }

  if (type === MEDIA_TYPES.INFO_TAG) {
    const detailInfotag = props.data;

    const handleReadmoreURL = (readMoreURL) => {
      if (
        readMoreURL.split(':')[0] === 'https' ||
        readMoreURL.split(':')[0] === 'http'
      ) {
        return readMoreURL;
      }
      return '//' + readMoreURL;
    };

    return (
      <div className="square-infotag">
        <div
          className="square-infotag--container"
          ref={containerRef}
          style={{
            transform: `translateY(-${translateY}%)`,
          }}
        >
          <div className="square-infotag--title">
            <p>{detailInfotag?.title}</p>
          </div>

          {detailInfotag?.infoTag?.text && (
            <div className="square-infotag--desc">
              <p>{limitLetters(detailInfotag.infoTag.text, 272)}</p>
            </div>
          )}

          {detailInfotag?.infoTag?.readMoreUrl && (
            <div className="square-infotag--readmore">
              <a
                href={handleReadmoreURL(detailInfotag.infoTag.readMoreUrl)}
                target="_blank"
                rel="noreferrer"
              >
                Readmore
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="square-icons" onClick={onClickHotspot}>
      <div className="square-icons--container">
        <div className="square-icons--icon">
          {type === MEDIA_TYPES.IMAGE ? (
            <img
              className="square-icons--img"
              src={props.data.imageUrl}
              alt="img-icon"
            />
          ) : (
            <Icon />
          )}
        </div>
        <div className="square-icons--extra-show">
          <div className="square-icons--title">
            <p>{limitLetters(title, 20)}</p>
          </div>
          <div className="square-icons--type">
            <p>{type}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

import Icon from './square-icons/floor-plan.png';

const SquareMaploorplanIcon = () => {
  return (
    <img style={{ height: 30, width: 30 }} src={Icon} alt="floorplan-icon" />
  );
};

export default SquareMaploorplanIcon;

import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import { BackSide } from 'three';
import { gsap } from 'gsap';
import configs from 'configs';

import { CustomShader } from './fadeMaterial';

const CrossFadeMaterial = (
  {
    texture1,
    texture2,
    index,
    mixFactor,
    opacity = 1.0,
    defaultOpacity = 1.0,
    isDefaultTexture = false,
  },
  ref
) => {
  const materialRef = useRef();
  const opacityRef = useRef(opacity);
  const uniforms = useMemo(
    () => ({
      mixFactor: { type: 'f', value: 0 },
      texture1: {
        type: 't',
        value: isDefaultTexture ? texture1 : undefined,
      },
      texture2: {
        type: 't',
        value: isDefaultTexture ? texture2 : undefined,
      },
      transparentOpacity: { type: 'f', value: defaultOpacity },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (opacity === -1) return;

    opacityRef.current = opacity;
    const anim = gsap.to(materialRef.current.uniforms.transparentOpacity, {
      value: opacity,
      duration: configs.transitionSpeed,
    });

    return () => anim && anim.pause();
  }, [opacity]);

  useEffect(() => {
    const anim = gsap.to(materialRef.current.uniforms.mixFactor, {
      value: mixFactor,
      duration: configs.transitionSpeed,
    });

    return () => anim && anim.pause();
  }, [mixFactor]);

  useEffect(() => {
    materialRef.current.uniforms.texture1.value = texture1;
    materialRef.current.needsUpdate = true;
  }, [texture1]);

  useEffect(() => {
    materialRef.current.uniforms.texture2.value = texture2;
    materialRef.current.needsUpdate = true;
  }, [texture2]);

  return (
    <shaderMaterial
      attach={index !== undefined ? `material-${index}` : 'material'}
      ref={materialRef}
      uniforms={uniforms}
      fragmentShader={CustomShader.fragment}
      vertexShader={CustomShader.vertex}
      side={BackSide}
      transparent={true}
    />
  );
};

export default forwardRef(CrossFadeMaterial);

import React from 'react';

import Icon from './square-icons/sketchfab.png';

const SquareSketchfabIcon = () => {
  return (
    <img
      style={{ height: 30, width: 30 }}
      src={Icon}
      alt="square-sketchfab-icon"
    />
  );
};

export default SquareSketchfabIcon;

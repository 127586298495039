import { useLoader } from '@react-three/fiber';
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import * as THREE from 'three';
import CrossFadeMaterial from './materials/material';

const PlaneImage = ({
  position,
  size,
  imageUrl0 = '',
  imageUrl1 = '',
  opacity,
  mixFactor = 0,
}) => {
  const texture0 = useLoader(THREE.TextureLoader, imageUrl0);
  const texture1 = useLoader(THREE.TextureLoader, imageUrl1 || imageUrl0);

  return (
    <mesh scale-x={-1} position={position}>
      <planeGeometry args={[size, size]} attach="geometry" />
      <CrossFadeMaterial
        texture1={texture0}
        texture2={texture1}
        mixFactor={mixFactor}
        opacity={opacity}
        isDefaultTexture
        defaultOpacity={1}
      />
    </mesh>
  );
};

const FaceGroup = (
  {
    side,
    size,
    opacity,
    mixFactor,
    sceneId,
    textures = [],
    indexActive = 0,
    onGetSceneData = () => {},
  },
  ref
) => {
  const finalTextures = useMemo(() => {
    return textures.map((item) => {
      return onGetSceneData({ sizeImg: size, side, textureId: item.id });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textures, size]);

  const handlePreloadImage = async ({ sizeImg, textureId }) => {
    const { positionPlane } = onGetSceneData({ sizeImg, side, textureId });

    return await Promise.all(
      positionPlane.map((item) => {
        return new Promise((resolve) => {
          new THREE.TextureLoader().load(item?.imageUrl, (texture) => {
            texture.needsUpdate = true;
            texture.encoding = THREE.sRGBEncoding;
            resolve({ data: item, texture });
          });
        });
      })
    );
  };

  useImperativeHandle(ref, () => ({
    preloadImage: handlePreloadImage,
  }));

  const groupRef = useRef(null);
  const { position, rotation, positionPlane } = finalTextures[indexActive];

  return (
    <group
      ref={groupRef}
      position={position}
      rotation={rotation}
      scale={[-1, 1, 1]}
    >
      {positionPlane.map((item, index) => (
        <PlaneImage
          key={item.imageUrl}
          {...item}
          imageUrl0={finalTextures[0]?.positionPlane[index]?.imageUrl}
          imageUrl1={finalTextures[1]?.positionPlane[index]?.imageUrl}
          opacity={opacity}
          mixFactor={mixFactor}
          sceneId={sceneId}
        />
      ))}
    </group>
  );
};

export default forwardRef(FaceGroup);

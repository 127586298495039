import React from 'react';

import Icon from './square-icons/embed.png';

const SquareEmbedIcon = () => {
  return (
    <img style={{ height: 30, width: 30 }} src={Icon} alt="square-embed-icon" />
  );
};

export default SquareEmbedIcon;

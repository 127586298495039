import { degToRad } from 'three/src/math/MathUtils';

export const SIDE = ['px', 'nx', 'py', 'ny', 'pz', 'nz'];

export const SIZE_IMAGE_TEXTURE = {
  SIZE_1024: 'size1024',
  SIZE_2048: 'size2048',
  SIZE_4096: 'size4096',
  SIZE_OLD: 'sizeOld',
};

export const SIZE_IMG = {
  [SIZE_IMAGE_TEXTURE.SIZE_4096]: {
    step: 250,
    start: -375,
    end: 375,
    quantity: 16,
  },
  [SIZE_IMAGE_TEXTURE.SIZE_2048]: {
    step: 500,
    start: -250,
    end: 250,
    quantity: 4,
  },
  [SIZE_IMAGE_TEXTURE.SIZE_1024]: {
    step: 1000,
    start: 0,
    end: 0,
    quantity: 1,
  },
  [SIZE_IMAGE_TEXTURE.SIZE_OLD]: {
    step: 1000,
    start: 0,
    end: 0,
    quantity: 1,
  },
};

export const SCENE_DATA = [
  {
    name: 'px',
    position: [499, 0, 0],
    rotation: [0, degToRad(90), 0],
  },
  {
    name: 'nx',
    position: [-499, 0, 0],
    rotation: [0, -degToRad(90), 0],
  },
  {
    name: 'py',
    position: [0, 499, 0],
    rotation: [-degToRad(90), 0, degToRad(0)],
  },
  {
    name: 'ny',
    position: [0, -499, 0],
    rotation: [degToRad(90), 0, 0],
  },
  {
    name: 'pz',
    position: [0, 0, 499],
    rotation: [0, 0, 0],
  },
  {
    name: 'nz',
    position: [0, 0, -499],
    rotation: [0, degToRad(180), 0],
  },
];

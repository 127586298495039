import React from 'react';

import Icon from './square-icons/google360.png';

const SquareGoogle360Icon = () => {
  return (
    <img
      style={{ height: 30, width: 30 }}
      src={Icon}
      alt="square-google360-icon"
    />
  );
};

export default SquareGoogle360Icon;

import React from 'react';

import Icon from './square-icons/3d.png';

const Square3DModelIcon = () => {
  return (
    <img style={{ height: 30, width: 30 }} src={Icon} alt="square-3d-icon" />
  );
};

export default Square3DModelIcon;

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useThree } from '@react-three/fiber';
import { gsap, Power4 } from 'gsap';

import { getCameraPositionFromLookAtCoordinates } from 'utils/positionHelper';
import { getCurvePoints } from './lookUtils';
import { setAutoRotate } from 'store/actions';
import configs from 'configs';

import Cube360 from './cube';
import TransparentCube from '../transparentCube';

import { TRANSITION_METHOD } from '../transition.const';
import { LAYOUT_UI } from 'consts/ui.const';
import ClearSpaceJumpSpots from '../ClearSpaceHotspot/ClearSpaceJumpSpots';

function Cube({ introClosed, ...props }) {
  const dispatch = useDispatch();
  const threeContext = useThree();

  const [isFirst, setIsFirst] = useState(true);
  const coords = useRef([]);
  const position = useRef(null);

  const viewMode = useSelector(({ viewMode }) => viewMode);
  const isPanning = useSelector(({ isPanning }) => isPanning);
  const tour = useSelector(({ tour }) => tour);
  const autoPlayMode = useSelector(({ autoPlayMode }) => autoPlayMode);

  const isClearSpaceStyle = tour?.menuStyle === LAYOUT_UI.CLEAR_SPACE;

  const isSimpleStyle = tour?.menuStyle === LAYOUT_UI.SIMPLE;

  useEffect(() => {
    window.isPanning = isPanning;
  }, [isPanning]);

  const setCamPosition = (x, y, z) => {
    threeContext.camera.position.set(x, y, z);
  };

  const handleLinear = () => {
    const { x, y, z } = position.current;
    setCamPosition(x, y, z);
  };

  const linearUpdate = (newPos) => {
    position.current = threeContext.camera.position.clone();
    // : { x: 0, y: 0, z: 1 };
    // eslint-disable-next-line
    gsap.to(position.current, {
      x: newPos.x,
      y: newPos.y,
      z: newPos.z,
      duration: configs.crossfadeSpeed,
      onUpdate: () => {
        if (window.isPanning) {
          // anim.pause();
          // props.onChangeLookAt();
          // props.toggleHideFootSteps(false);
          // return;
        }
        handleLinear();
      },
      onComplete: () => {
        props.onChangeLookAt();
        props.toggleHideFootSteps(false);
      },
    });
  };

  const firstLinearUpdate = () => {
    if (coords.current.length) {
      let indexing = { i: coords.current.length - 1 };
      const anim = gsap.to(indexing, {
        i: 0,
        duration: 6,
        ease: Power4.easeInOut,
        onUpdate: () => {
          if (window.isPanning) {
            anim.pause();
            props.onChangeLookAt();
            dispatch(setAutoRotate(true));
            return;
          }
          const round = Math.round(indexing.i);
          if (indexing.i === round) {
            const { x, y, z } = coords.current[round];
            setCamPosition(x, y, z);
          } else {
            const ceil = Math.ceil(indexing.i);
            const floor = Math.floor(indexing.i);
            const cPercent = indexing.i - floor;
            const fPercent = ceil - indexing.i;
            const x =
              coords.current[ceil].x * cPercent +
              coords.current[floor].x * fPercent;
            const y =
              coords.current[ceil].y * cPercent +
              coords.current[floor].y * fPercent;
            const z =
              coords.current[ceil].z * cPercent +
              coords.current[floor].z * fPercent;
            setCamPosition(x, y, z);
          }
        },
        onComplete: () => {
          props.onChangeLookAt();
          dispatch(setAutoRotate(true));
        },
      });
    } else {
      props.onChangeLookAt();
    }
  };

  const firstAnimateCamera = ([x, y, z]) => {
    coords.current = getCurvePoints(x, y, z).map((p) => {
      return getCameraPositionFromLookAtCoordinates(p.x, p.y, p.z);
    });
    position.current = coords.current.pop();
    handleLinear();
    props.onChangeLookAt();
  };

  const turnOffIntroAnimate = ([x, y, z]) => {
    position.current = getCameraPositionFromLookAtCoordinates(x, y, z);
    handleLinear();
    props.onChangeLookAt();
  };

  const jumpAnimate = ([x, y, z]) => {
    const newPos = getCameraPositionFromLookAtCoordinates(x, y, z);
    linearUpdate(newPos);
  };

  const animateCamera = (lookAtCoords) => {
    if (
      (viewMode === 'no-intro' && window.isIntroAnimation) ||
      autoPlayMode ||
      isSimpleStyle
    ) {
      turnOffIntroAnimate(lookAtCoords);
      window.isIntroAnimation = false;
      return;
    }

    // todo: huunhatdev
    if (false && window.isIntroAnimation) {
      firstAnimateCamera(lookAtCoords);
      window.isIntroAnimation = false;
      return;
    }

    if (window.transitionMethod !== TRANSITION_METHOD.GROUND_NAV) {
      jumpAnimate(lookAtCoords);
      return;
    }

    props.onChangeLookAt();
  };

  useEffect(() => {
    if (isFirst && introClosed && coords.current.length) {
      window.logMessage('introClosed', introClosed);
      firstLinearUpdate();
      // position.current = coords.current[0];
      // handleLinear();
      // props.onChangeLookAt();
      setIsFirst(false);
    }
    // eslint-disable-next-line
  }, [introClosed]);

  return (
    <>
      <Cube360 {...props} animateCamera={animateCamera} />
      <TransparentCube
        {...props.stepHandlers}
        introClosed={introClosed}
        scenes={props.scenes}
        hideFootSteps={props.hideFootSteps}
      />
      {isClearSpaceStyle && <ClearSpaceJumpSpots />}
    </>
  );
}

export default Cube;

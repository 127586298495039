import React from 'react';

import Icon from './square-icons/matterport.png';

const SquareMatterportIcon = () => {
  return (
    <img
      style={{ height: 30, width: 30 }}
      src={Icon}
      alt="square-matterport-icon"
    />
  );
};

export default SquareMatterportIcon;

import React from 'react';

const getHtml = (url) => ({
  __html: `<iframe allow="autoplay; fullscreen; xr-spatial-tracking" src="${url}"></iframe>`,
});

function PopupLink({ media }) {
  const url = media?.link?.url;
  if (!url) return null;
  const embedHtml = getHtml(url);

  return (
    <div className="Popup-content Popup-content-iframe">
      <div className="Popup-content-wp">
        <div
          className="html-embeded-content"
          dangerouslySetInnerHTML={embedHtml}
        ></div>
      </div>
    </div>
  );
}

export default PopupLink;

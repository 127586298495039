import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="banner">
      <p>{t('start_creating_tour_in_10_minutes')}</p>
      <p
        className="banner-button"
        onClick={() => {
          window.open(process.env.REACT_APP_URL_PORTAL, '_blank');
        }}
      >
        {t('start_now')}
      </p>
    </div>
  );
};

export default Banner;

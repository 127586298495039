import React from 'react';

import Icon from './square-icons/lumas.png';

const SquareLumasIcon = () => {
  return (
    <img style={{ height: 30, width: 30 }} src={Icon} alt="square-lumas-icon" />
  );
};

export default SquareLumasIcon;

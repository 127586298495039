import React from 'react';
import HOTSPOT_TYPE from '../../../consts/hotspotType.const';
import SceneHotspotIcon from './scene';
import MediaHotspotIcon from './media';
import SquareHotspotIcon from './SquareHotspotIcon';

const HotspotIcon = (props) => {
  const { type, scene, media, index, tour, isActiveIcon, isSquareStyle } =
    props;
  const Icon =
    type === HOTSPOT_TYPE.SCENE ? SceneHotspotIcon : MediaHotspotIcon;
  const data = type === HOTSPOT_TYPE.SCENE ? { ...scene } : { ...media };

  if (isSquareStyle)
    return (
      <SquareHotspotIcon
        {...props}
        data={data}
        index={index}
        tour={tour}
        isActiveIcon={isActiveIcon}
      />
    );

  return (
    <Icon
      {...props}
      data={data}
      index={index}
      tour={tour}
      isActiveIcon={isActiveIcon}
    />
  );
};

export default HotspotIcon;

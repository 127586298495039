import React from 'react';

import Icon from './square-icons/typeform.png';

const SquareTypeformIcon = () => {
  return (
    <img style={{ height: 30, width: 30 }} src={Icon} alt="typeform-icon" />
  );
};

export default SquareTypeformIcon;

import React, { useRef, useEffect, useCallback } from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { connect, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { trackEventByType, EVENT_TYPE, trackTourContentOpen } from 'gaTracking';
import CloseButton from 'components/buttons/closeButton';
import { formatPopupStyle } from 'utils/cssHelper';
import { isMenuLeft } from 'utils/menuHelper';
import MEDIA_TYPE from 'consts/mediaType.const';
import { useNavigatedRoutes } from 'common/hooks';

import PopupInfoBox from './popup.infoBox';
import PopupYoutube from './popup.youtube';
import PopupGallery from './popup.gallery';
import PopupMatterport from './popup.matterport';
import PopupMapFloorPlan from './popup.mapFloorPlan';
import PopupTypeForm from './popup.typeform';
import PopupGoogle360 from './popup.google360';
import VideoPopup from './popup.video';
import ShapeSparkPopup from './popup.shapeSpark';

import './style.scss';
import PopupSketchFab from './popup.sketchfab';
import { PopupModel3D } from './popup.model3d';
import { setOpenPopUp } from 'store/actions';
import PopupPdf from './popup.pdf';
import PopupLuma from './popup.luma';
import PopupEmbed from './popup.embed';
import PopupLink from './popup.link';

function HotspotPopup(props) {
  const {
    media,
    color1,
    color2,
    color3,
    headerColor,
    bodyColor,
    menuList,
    scenes,
    hotspotHistory,
    tour,
    currentPano,
    selectSceneById,
  } = props;
  const hotspotBody = useRef(null);
  const ps = useRef();

  const scrollTop = () => {
    const curr = ps.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  };
  const [handleUpdateRoute] = useNavigatedRoutes();
  useEffect(() => {
    const hotspotBodyEl = hotspotBody?.current;
    if (hotspotBodyEl) {
      disableBodyScroll(hotspotBodyEl);
    }
    return () => {
      if (hotspotBodyEl) {
        enableBodyScroll(hotspotBodyEl);
      }
    };
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    // GA Tracking
    if (currentPano && tour && media) {
      trackTourContentOpen({
        media_id: media._id,
        tour_id: tour.id,
        scene_id: currentPano._id,
      });
    }

    trackEventByType(EVENT_TYPE.HOTSPOT, media.id);
  }, [media, currentPano, tour]);

  const handleCloseHotspotPopup = useCallback(() => {
    handleUpdateRoute();
    dispatch(setOpenPopUp(false));
  }, [dispatch, handleUpdateRoute]);

  return (
    <div
      className="Hotspot-popup"
      style={formatPopupStyle(color1, color2, color3)}
    >
      <div className="Hotspot-popup-header">
        <span className="Close-button" onClick={handleCloseHotspotPopup}>
          <CloseButton />
        </span>
      </div>
      <div
        ref={hotspotBody}
        className={`Hotspot-popup-body ${
          isMenuLeft(props.dimentions) ? '' : 'Menu-bottom'
        }`}
      >
        <div className="Popup-body-content">
          <PerfectScrollbar containerRef={(el) => (ps.current = el)}>
            {[media].map((hs) => {
              if (hs.type === MEDIA_TYPE.IMAGE) {
                return (
                  <PopupInfoBox
                    tour={tour}
                    media={media}
                    headerColor={headerColor}
                    bodyColor={bodyColor}
                    scrollTop={scrollTop}
                    key="PopupInfoBox"
                  />
                );
              } else if (hs.type === MEDIA_TYPE.PDF) {
                return (
                  <PopupPdf tour={tour} media={media} key="PopupInfoBox" />
                );
              } else if (hs.type === MEDIA_TYPE.YOUTUBE) {
                return (
                  <PopupYoutube
                    url={media.youtubeID || media.youtubeId}
                    key="PopupYoutube"
                  />
                );
              } else if (hs.type === MEDIA_TYPE.GALLERY) {
                return (
                  <PopupGallery
                    media={media}
                    headerColor={headerColor}
                    bodyColor={bodyColor}
                    scrollTop={scrollTop}
                    key="PopupGallery"
                  />
                );
              } else if (hs.type === MEDIA_TYPE.MATTERPORT) {
                return <PopupMatterport media={media} key="PopupMatterport" />;
              } else if (hs.type === MEDIA_TYPE.MAPFLOORPLAN) {
                return (
                  <PopupMapFloorPlan
                    media={media}
                    key="MapFloorPlan"
                    menuList={menuList}
                    scenes={scenes}
                    tour={tour}
                    hotspotHistory={hotspotHistory}
                    selectSceneById={selectSceneById}
                  />
                );
              } else if (hs.type === MEDIA_TYPE.TYPEFORM) {
                return <PopupTypeForm media={media} key="PopupTypeForm" />;
              } else if (hs.type === MEDIA_TYPE.GOOGLE360) {
                return <PopupGoogle360 media={media} key="PopupGoogle360" />;
              } else if (hs.type === MEDIA_TYPE.SHAPESPARK) {
                return <ShapeSparkPopup media={media} key="PopupShapeSpark" />;
              } else if (hs.type === MEDIA_TYPE.VIDEO) {
                return <VideoPopup media={media} key="PopupVideo" />;
              } else if (hs.type === MEDIA_TYPE.SKETCH_FAB) {
                return <PopupSketchFab media={media} key="PopupSketchfab" />;
              } else if (hs.type === MEDIA_TYPE.EMBED) {
                return <PopupEmbed media={media} key="PopupEmbed" />;
              } else if (hs.type === MEDIA_TYPE.LINK) {
                return <PopupLink media={media} key="PopupLink" />;
              } else if (hs.type === MEDIA_TYPE.LUMA) {
                return <PopupLuma media={media} key="PopupLuma" />;
              } else if (hs.type === MEDIA_TYPE.MODEL_3D) {
                return <PopupModel3D media={media} key="PopupModel3D" />;
              } else {
                return null;
              }
            })}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ dimentions, json }) => ({
  dimentions,
  color1: json?.tour?.lightboxBackgroundColor1 || '',
  color2: json?.tour?.lightboxBackgroundColor2 || '',
  color3: json?.tour?.lightboxBackgroundColor3 || '',
  headerColor: json?.tour?.lightboxHeaderColor || '',
  bodyColor: json?.tour?.lightboxBodyTextColor || '',
});

export default connect(mapStateToProps)(HotspotPopup);

import React from 'react';

import Icon from './square-icons/shapespark.png';

const SquareShapesparkIcon = () => {
  return (
    <img
      style={{ height: 30, width: 30 }}
      src={Icon}
      alt="square-shapespark-icon"
    />
  );
};

export default SquareShapesparkIcon;
